.btnPrimary {
  background-color: #204090;
  transition: all 0.3s;
  cursor: pointer;
  color: #fff;
  user-select: none;
}

.btnPrimary:hover {
  background-color: #232222;
  color: #fff;
  transition: all 0.3s;
}

.btnLight {
  background-color: #fff;
  transition: all 0.3s;
  cursor: pointer;
  color: #232222;
  user-select: none;
}

.btnLight:hover {
  background-color: #232222;
  color: #fff;
  transition: all 0.3s;
}

.img {
  display: block;
  width: 100%;
  z-index: 0;
  object-fit: cover;
  border-radius: 10px;
  height: 100%;
  transform: scale(1);
  transition: 1s all;
  position: relative;
}

.img:hover {
  transform: scale(1.3);
  transition: 1s all;
}