.swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    opacity: 1;
    background: #e7e82b;
    border-radius: 10px;
    transition: all .3s;
}

.swiper-pagination-bullet-active {
    width: 20px;
    height: 10px;
    background: #204090;
    border-radius: all .3s;
}